<script>
import { Button, Tabs, TabPane } from 'element-ui'
import MakeOrganization from './MakeOrganization'
export default {
  components: {
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    MakeOrganization
  },
  data() {
    return {
      activeTab: '1',
      tabs: [
        {
          name: '1',
          title: this.$gettext('Organization'),
          content: 'dfg',
          component: 'MakeOrganization',
        }
      ],
      currentTab: 'MakeOrganization',
    }
  },
  methods: {
    selectTab(tab) {
      const index = this.tabs.findIndex(item => item.name === tab.name)
      if (index >= 0) {
        this.currentTab = this.tabs[index].component
      }
    },
  },
}
</script>

<template>
  <el-tabs
    v-model="activeTab"
    @tab-click="(e) => selectTab(e)"
  >
    <el-tab-pane
      v-for="item in tabs"
      :key="item.name"
      :label="item.title"
      :name="item.name"
    >
      <component :is="currentTab" />
    </el-tab-pane>
  </el-tabs>
</template>>

<style></style>
