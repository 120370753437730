<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 class="card-title">
          Register Form
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Email address</label>
          <fg-input
            v-model="model.email"
            v-validate="modelValidations.email"
            type="email"
            name="email"
            :error="getError('email')"
          />
        </div>
        <div class="form-group">
          <label>Password</label>
          <fg-input
            v-model="model.password"
            v-validate="modelValidations.password"
            type="password"
            name="password"
            :error="getError('password')"
          />
        </div>
        <div class="form-group">
          <label>Confirm Password</label>
          <fg-input
            v-model="model.confirmPassword"
            v-validate="modelValidations.confirmPassword"
            type="password"
            name="confirm"
            :error="getError('confirm')"
          />
        </div>
      </div>
      <div class="card-footer text-right">
        <p-checkbox class="pull-left">
          Subscribe to newsletter
        </p-checkbox>
        <p-button
          type="info"
          @click.prevent="validate"
        >
          Register
        </p-button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      modelValidations: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          min: 5
        },
        confirmPassword: {
          required: true,
          confirmed: 'password'
        }
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit('on-submit', this.registerForm, isValid)
      })
    }
  }
}
</script>
<style>
</style>
