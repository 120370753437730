<template>
  <card class="form-horizontal">
    <h4
      slot="header"
      class="card-title"
    >
      Type Validation
    </h4>

    <div class="row">
      <label class="col-sm-2 col-form-label">Required Text</label>
      <div class="col-sm-7">
        <fg-input
          v-model="model.requiredText"
          v-validate="modelValidations.requiredText"
          type="text"
          name="requiredText"
          :error="getError('requiredText')"
        />
      </div>
      <div class="col-sm-3 label-on-right">
        <code>required:true</code>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Email</label>
      <div class="col-sm-7">
        <fg-input
          v-model="model.email"
          v-validate="modelValidations.email"
          type="text"
          name="email"
          :error="getError('email')"
        />
      </div>
      <div class="col-sm-3 label-on-right">
        <code>email:true</code>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Number</label>
      <div class="col-sm-7">
        <fg-input
          v-model="model.number"
          v-validate="modelValidations.number"
          type="text"
          name="number"
          :error="getError('number')"
        />
      </div>
      <div class="col-sm-3 label-on-right">
        <code>number:true</code>
      </div>
    </div>


    <div class="row">
      <label class="col-sm-2 col-form-label">Url</label>
      <div class="col-sm-7">
        <fg-input
          v-model="model.url"
          v-validate="modelValidations.url"
          type="text"
          name="url"
          :error="getError('url')"
        />
      </div>
      <div class="col-sm-3 label-on-right">
        <code>url:true</code>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Equal to</label>
      <div class="col-sm-3">
        <fg-input
          v-model="model.idSource"
          v-validate="modelValidations.idSource"
          type="text"
          name="idSource"
          placeholder="#idSource"
          :error="getError('idSource')"
        />
      </div>
      <div class="col-sm-3">
        <fg-input
          v-model="model.idDestination"
          v-validate="modelValidations.idDestination"
          type="text"
          name="idDestination"
          placeholder="#idDestination"
          :error="getError('idDestination')"
        />
      </div>
      <div class="col-sm-3 label-on-right">
        <code>confirmed: 'idSource'</code>
      </div>
    </div>

    <div
      slot="footer"
      class="text-center"
    >
      <p-button
        type="info"
        @click.prevent="validate"
      >
        Validate inputs
      </p-button>
    </div>
  </card>
</template>
<script>
import { Card } from 'src/components/UIComponents'

export default {
  components: {
    Card
  },
  data() {
    return {
      model: {
        requiredText: '',
        email: '',
        number: '',
        url: '',
        idSource: '',
        idDestination: ''
      },
      modelValidations: {
        requiredText: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        number: {
          required: true,
          decimal: true
        },
        url: {
          required: true,
          url: true
        },
        idSource: {
          required: true
        },
        idDestination: {
          required: true,
          confirmed: 'idSource'
        }
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit('on-submit', this.registerForm, isValid)
      })
    }
  }
}
</script>
<style>
</style>
