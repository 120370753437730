<script>
import { Input, Button } from 'element-ui'
export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    edit() {
      this.$emit('editField', this.value)
    },
  },
}
</script>

<template>
  <div>
    <el-input v-model="value[1]" type="textarea" autosize />
    <el-button @click="edit">
      <translate>Edit</translate>
    </el-button>
  </div>
</template>

<style />
