<script>
import { Input, Button, Alert } from 'element-ui'
export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [Alert.name]: Alert,
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      valueString: JSON.stringify(this.value[1]),
      error: '',
    }
  },
  methods: {
    edit() {
      this.error = ''
      try {
        this.$emit('editField', [this.value[0], JSON.parse(this.valueString)])
      } catch (e) {
        this.error = e.toString()
      }
    },
    changeTrueValue(value) {
      this.valueString = value
    },
  },
}
</script>

<template>
  <div>
    <el-input
      :value="valueString"
      type="textarea"
      autosize
      @input="(e) => changeTrueValue(e)"
    />
    <el-button @click="edit">
      <translate>Edit</translate>
    </el-button>
    <el-alert v-if="error" :title="error" type="error" />
  </div>
</template>

<style />
