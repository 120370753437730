<script>
import {
  Button,
  Steps,
  Step,
  Form,
  FormItem,
  Input,
  Divider,
  Alert,
  Select,
  Option,
  Tree,
  Table,
  TableColumn,
} from 'element-ui'
import InspectorStringField from './InspectorStringField'
import InspectorJSONField from './InspectorJSONField'
import InspectorBooleanField from './InspectorBooleanField'
import { isEmpty } from 'lodash'
import lazyLoad from '@nova/Mixins/lazyLoad'
export default {
  components: {
    [Button.name]: Button,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Divider.name]: Divider,
    [Alert.name]: Alert,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tree.name]: Tree,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    InspectorStringField,
    InspectorJSONField,
    InspectorBooleanField,
  },
  mixins: [lazyLoad],
  data() {
    return {
      organization: {},
      newOrganization: {},
      organizations: [],
      owner: {},
      newUser: {},
      users: [],
      owners: [],
      ownerRole: null,
      project: {},
      newProject: {},
      projects: [],
      task: {},
      tasks: [],
      error: [],
      selectedInspectorItem: null,
      inspectorComponent: '',
      load: this.loadOrganizations,
    }
  },
  computed: {
    /**
     * Текущая стадия для компонента steps
     * @return {number} - стадия
     */
    progress() {
      if (!isEmpty(this.task)) return 4
      else if (!isEmpty(this.project)) return 3
      else if (!isEmpty(this.owner)) return 2
      else if (!isEmpty(this.organization)) return 1
      else return 0
    },
    /**
     * возвращает структуру для el-tree
     * @return {*[]|[string, unknown][]}
     */
    currentTaskTree() {
      if (this.task) {
        return Object.entries(this.task).filter(
          (item) =>
            item[0] === 'name' ||
            item[0] === 'orthophoto_extent' ||
            item[0] === 'dsm_extent' ||
            item[0] === 'dtm_extent' ||
            item[0] === 'public' ||
            item[0] === 'uuid'
        )
      } else {
        return []
      }
    },
    titleSecondStep() {
      return this.owner.user
        ? this.owner.user.email || this.$gettext('Owner')
        : this.$gettext('Owner')
    },
  },
  methods: {
    /**
     * создать организацию
     * @return {Promise<void>}
     */
    async makeOrganization() {
      this.error = []
      try {
        const response = await this.axios.post('/organizations/', this.newOrganization)
        if (response.status === 201) {
          await this.loadOrganizations()
          this.organization = response.data
        } else {
          debugger
        }
      } catch (e) {
        if (e.response.status === 400) this.showError(e.response.data)
        console.error(e)
      }
    },
    /**
     * Создать пользователя
     * @return {Promise<void>}
     */
    async makeUser() {
      this.error = []
      try {
        const response = await this.axios.post(
          `/organizations/${this.organization.id}/users/`,
          this.newUser
        )
        if (response.status === 201) {
          await this.loadUsers()
        } else {
          debugger
        }
      } catch (e) {
        if (e.response.status === 400) this.showError(e.response.data)
        console.error(e)
      }
    },
    /**
     * Сделать пользователя овнером
     * @param scope - пользователь
     * @return {Promise<void>}
     */
    async makeOwner(scope) {
      try {
        if (scope.id) {
          if (!this.ownerRole) {
            const response = await this.axios.get(
              `/organizations/${this.organization.id}/roles/`
            )
            if (response.status === 200) {
              this.ownerRole = response.data.results[0].id
            }
          }
          const response = await this.axios.patch(
            `/organizations/${this.organization.id}/profiles/${scope.id}/`,
            {
              roles: [this.ownerRole],
            }
          )
          if (response.status === 200) {
            this.owner = scope
          }
        }
      } catch (e) {
        if (e.response.status === 400) this.showError(e.response.data)
        console.error(e)
      }
    },
    /**
     * Убрать роль овнера у пользователя
     * @param user - пользователь
     * @return {Promise<void>}
     */
    async demakeOwner(user) {
      try {
        if (user) {
          if (!this.ownerRole) {
            const response = await this.axios.get(
              `/organizations/${this.organization.id}/roles/`
            )
            if (response.status === 200) {
              this.ownerRole = response.data.results[0].id
            }
          }
          const roles = user.roles
          const subIndex = roles.findIndex((item) => item.id === this.ownerRole)
          if (subIndex >= 0) {
            roles.splice(subIndex, 1)
            const response = await this.axios.patch(
              `/organizations/${this.organization.id}/profiles/${user.id}/`,
              {
                roles,
              }
            )
            if (response.status === 200) {
              if (this.owner === user) {
                this.owner = {}
              }
              this.owners.splice(this.owners.findIndex((item) => item.id === user.id), 1)
            }
          }
        }
      } catch (e) {
        if (e.response.status === 400) this.showError(e.response.data)
        console.error(e)
      }
    },
    /**
     * Создать проект
     * @return {Promise<void>}
     */
    async makeProject() {
      this.error = []
      try {
        this.newProject.organizations = [this.organization.id]
        const response = await this.axios.post('/projects/', this.newProject)
        if (response.status === 201) {
          this.loadProjects()
          this.project = response.data.id
        } else {
          debugger
        }
      } catch (e) {
        if (e.response.status === 400) this.showError(e.response.data)
        console.error(e)
      }
    },
    /**
     * Создать таск
     * @return {Promise<void>}
     */
    async makeTask() {
      this.error = []
      try {
        const response = await this.axios.post(`/projects/${this.project.id}/tasks/`, {})
        if (response.status === 201) {
          await this.loadTasks()
          this.task = response.data
        } else {
          debugger
        }
      } catch (e) {
        if (e.response.status === 400) this.showError(e.response.data)
        console.error(e)
      }
    },
    /**
     * Загрузка организаций
     * @param page - страница если есть
     * @return {Promise<void>}
     */
    async loadOrganizations(page) {
      try {
        if (!page) {
          this.organizations = []
          const response = await this.axios.get('/organizations/')
          if (response.status === 200) {
            if (response.data.next) {
              this.nextApiPage = response.data.next
            }
            response.data.results.map((item) => this.organizations.push(item))
          }
        } else {
          let url = '/'
          page.split('/').map((item) => {
            if (item === 'organizations') url += item
            else if (url.length > 1) url += '/' + item
          })
          const response = await this.axios.get(url)
          if (response.status === 200) {
            if (response.data.next) {
              this.nextApiPage = response.data.next
            } else {
              this.nextApiPage = null
            }
            response.data.results.map((item) => this.organizations.push(item))
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * Загрузка пользователей
     * @param page - страница если есть
     * @return {Promise<void>}
     */
    async loadUsers(page) {
      try {
        if (!page) {
          this.users = []
          const response = await this.axios.get(
            `/organizations/${this.organization.id}/profiles/`
          )
          if (response.status === 200) {
            if (response.data.next) {
              this.nextApiPage = response.data.next
            } else {
              this.nextApiPage = null
            }
            response.data.results.map((item) => {
              this.users.push(item)
              item.roles.map((subItem) => {
                if (subItem.short_name === 'owner') {
                  this.owners.push(item)
                  this.ownerRole = subItem.id
                }
              })
            })
          }
        } else {
          let url = '/'
          page.split('/').map((item) => {
            if (item === 'organizations') url += item
            else if (url.length > 1) url += '/' + item
          })
          const response = await this.axios.get(url)
          if (response.status === 200) {
            if (response.data.next) {
              this.nextApiPage = response.data.next
            } else {
              this.nextApiPage = null
            }
            response.data.results.map((item) => {
              this.users.push(item)
              item.roles.map((subItem) => {
                if (subItem.short_name === 'owner') {
                  this.owners.push(item)
                }
              })
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * Загрузка проектов
     * @param page - страница если есть
     * @return {Promise<void>}
     */
    async loadProjects(page) {
      try {
        if (!page) {
          this.projects = []
          const response = await this.axios.get(
            `/organizations/${this.organization.id}/projects/`
          )
          if (response.status === 200) {
            if (response.data.next) {
              this.nextApiPage = response.data.next
            }
            response.data.results.map((item) => this.projects.push(item))
          }
        } else {
          let url = '/'
          page.split('/').map((item) => {
            if (item === 'organizations') url += item
            else if (url.length > 1) url += '/' + item
          })
          const response = await this.axios.get(url)
          if (response.status === 200) {
            if (response.data.next) {
              this.nextApiPage = response.data.next
            } else {
              this.nextApiPage = null
            }
            response.data.results.map((item) => this.projects.push(item))
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * Загрузка тасков
     * @return {Promise<void>}
     */
    async loadTasks() {
      this.tasks = []
      this.task = {}
      try {
        const response = await this.axios.get(`/projects/${this.project.id}/tasks/`)
        if (response.status === 200) {
          response.data.map((item) => {
            this.tasks.push(item)
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * Смена ноды в el-tree
     * @param node
     */
    changeNode(node) {
      this.selectedInspectorItem = node
      switch (node[0]) {
        case 'uuid':
        case 'name':
          this.inspectorComponent = 'InspectorStringField'
          break
        case 'orthophoto_extent':
        case 'dsm_extent':
        case 'dtm_extent':
          this.inspectorComponent = 'InspectorJSONField'
          break
        // case 'available_assets':
        //   this.inspectorComponent = 'InspectorArrayField'
        //   break
        case 'public':
          this.inspectorComponent = 'InspectorBooleanField'
          break
        default:
          this.inspectorComponent = ''
          break
      }
    },
    /**
     * Обновление таска
     * @param field - поле которое надо обновить
     * @return {Promise<void>}
     */
    async updateTask(field) {
      this.error = []
      const taskToSave = {}
      taskToSave[field[0]] = field[1]
      try {
        const response = await this.axios.patch(
          `/projects/${this.project.id}/tasks/${this.task.id}/`,
          taskToSave
        )
        if (response.status === 200) {
          alert('успех')
        }
      } catch (e) {
        if (e.response.status === 400) {
          this.showError(e.response.data)
        }
        console.error(e)
      }
    },
    /**
     * Показать ошибку
     * @param error - ошибка
     */
    showError(error) {
      Object.keys(error).map((key) => {
        this.error.push(error[key])
      })
    },
    /**
     * Выбрать организацию
     * @param scope - организация
     */
    chooseOrganization(scope) {
      this.organization = scope
      this.users = []
      this.owners = []
      this.owner = {}
      this.project = {}
      this.task = {}
      this.ownerRole = null
      this.load = this.loadUsers
      this.nextApiPage = null
      this.load()
    },
    /**
     * Выбрать овнера
     * @param scope - овнер
     */
    chooseOwner(scope) {
      this.owner = scope
      this.load = this.loadProjects
      this.nextApiPage = null
      this.load()
    },
    /**
     * Выбрать проект
     * @param scope - проект
     */
    chooseProject(scope) {
      this.project = scope
      this.load = this.loadTasks
      this.nextApiPage = null
      this.load()
    },
    /**
     * Выбрать таск
     * @param scope - таск
     */
    chooseTask(scope) {
      this.task = scope
      this.load = () => {}
      this.nextApiPage = null
      this.selectedInspectorItem = null
    },
    /**
     * Вернуть состояние страницы к началу - выбору организации
     */
    dropToStart() {
      this.load = this.loadOrganizations
      this.nextApiPage = null
      this.load()
    },
    /**
     * Очистка выбранных филдов в зависимости от выбранного этапа к которому надо сбросить
     * @param stage - этап
     */
    clearFields(stage) {
      if (stage < 4) {
        this.task = {}
        if (stage === 3) {
          this.chooseProject(this.project)
        }
      }
      if (stage < 3) {
        this.project = {}
        if (stage === 2) {
          this.chooseOwner(this.owner)
        }
      }
      if (stage < 2) {
        this.owner = {}
        if (stage === 1) {
          this.chooseOrganization(this.organization)
        }
      }
      if (stage < 1) {
        this.organization = {}
        if (stage === 0) {
          this.dropToStart()
        }
      }
    },
  },
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <el-steps :active="progress" finish-status="success" align-center>
          <el-step
            :title="organization.name || 'Organization' | translate"
            @click.native="clearFields(0)"
          />
          <el-step :title="titleSecondStep" @click.native="clearFields(1)" />
          <el-step
            :title="project.name || 'Project' | translate"
            @click.native="clearFields(2)"
          />
          <el-step
            :title="task.name ? task.name : task.id || 'Task' | translate"
            @click.native="clearFields(3)"
          />
          <el-step :title="'Task edit' | translate" />
        </el-steps>
      </div>
    </div>
    <el-divider />
    <div class="row">
      <div v-if="progress === 0" key="org" class="col">
        <el-input
          v-model="newOrganization.name"
          :placeholder="'New organization' | translate"
        />
        <el-button @click="makeOrganization">
          <translate>Make organization</translate>
        </el-button>
        <el-table :data="organizations" style="width: 100%">
          <el-table-column :label="'id' | translate" prop="id" />
          <el-table-column :label="'name' | translate" prop="name" />
          <el-table-column :label="'Actions' | translate" prop="actions">
            <template slot-scope="scope">
              <button @click="chooseOrganization(scope.row)">
                <i class="fas fa-user" />
                <translate>choose</translate>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="progress === 1" key="owner" class="col">
        <el-input
          v-model="newUser.username"
          :placeholder="'New user username' | translate"
        />
        <el-input
          v-model="newUser.password"
          :placeholder="'New user password' | translate"
        />
        <el-input v-model="newUser.email" :placeholder="'New user email' | translate" />
        <el-button @click="makeUser">
          <translate>Make user</translate>
        </el-button>
        <el-table :data="users" style="width: 100%">
          <el-table-column :label="'email' | translate" prop="user.email" />
          <el-table-column :label="'username' | translate" prop="user.username" />
          <el-table-column :label="'Actions' | translate" prop="actions">
            <template slot-scope="scope">
              <div v-if="owners.findIndex((item) => item === scope.row) >= 0">
                <button @click="demakeOwner(scope.row)">
                  <i class="fas fa-user" />
                  <translate>demake owner</translate>
                </button>
                <button @click="chooseOwner(scope.row)">
                  <i class="fas fa-user" />
                  <translate>choose owner</translate>
                </button>
              </div>
              <button v-else @click="makeOwner(scope.row)">
                <i class="fas fa-user" />
                <translate>make and choose owner</translate>
              </button>
            </template>
          </el-table-column>
          <el-table-column :label="'owner' | translate" align="right">
            <template slot-scope="scope">
              <div
                v-show="owners.findIndex((item) => item === scope.row) >= 0"
                v-translate
              >
                yes
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="progress === 2" key="project" class="col">
        <el-input v-model="newProject.name" :placeholder="'New project' | translate" />
        <el-button @click="makeProject">
          <translate>Make project</translate>
        </el-button>
        <el-table :data="projects" style="width: 100%">
          <el-table-column :label="'id' | translate" prop="id" />
          <el-table-column :label="'name' | translate" prop="name" />
          <el-table-column :label="'Actions' | translate" prop="actions">
            <template slot-scope="scope">
              <button @click="chooseProject(scope.row)">
                <i class="fas fa-user" />
                <translate>choose project</translate>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="progress === 3" key="task" class="col">
        <el-button @click="makeTask">
          <translate>Make task</translate>
        </el-button>
        <el-table :data="tasks" style="width: 100%">
          <el-table-column :label="'id' | translate" prop="id" />
          <el-table-column :label="'name' | translate" prop="name" />
          <el-table-column :label="'Actions' | translate" prop="actions">
            <template slot-scope="scope">
              <button @click="chooseTask(scope.row)">
                <i class="fas fa-user" />
                <translate>choose task</translate>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="progress === 4" key="taskEdit" class="col">
        <div class="row">
          <div class="col-5">
            <el-tree
              :data="currentTaskTree"
              :props="{ label: '0' }"
              @current-change="changeNode"
            />
          </div>
          <div v-if="selectedInspectorItem" :key="selectedInspectorItem" class="col-7">
            <component
              :is="inspectorComponent"
              :value="selectedInspectorItem"
              @editField="(e) => updateTask(e)"
            />
          </div>
        </div>
      </div>
      <div v-if="error.length" key="error" class="col">
        <el-alert
          v-for="(item, index) in error"
          :key="index"
          :title="item"
          type="error"
        />
      </div>
      <el-button
        v-if="nextApiPage"
        icon="el-icon-arrow-down"
        class="fixed-scroll-button"
        @click="load(nextApiPage)"
      >
        <translate>Scroll down or click here to load more</translate>
      </el-button>
    </div>
  </div>
</template>

<style>
.el-tree {
  max-height: 100%;
}
</style>
