export default {
  data() {
    return {
      loading: false,
      // Долистали ли до низа страницы
      bottom: false,
      // Ссылка на следующую страницу в апи
      nextApiPage: null,
      load: (e) => { return e },
    }
  },
  watch: {
    /**
     * Слежение за скроллом и подгрузка
     */
    bottom(bottom) {
      if (bottom && this.nextApiPage) {
        this.load(this.nextApiPage)
      }
    }
  },
  async mounted() {
    // Листенер ивента скролла чтобы подгружать если есть еще не загруженные
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
    if ('onwheel' in window) {
      window.addEventListener('wheel', () => {
        this.bottom = this.bottomVisible()
      })
    } else if ('onmousewheel' in document) {
      window.addEventListener('mousewheel', () => {
        this.bottom = this.bottomVisible()
      })
    }
    this.externalLoad()
    await this.load()
  },
  methods: {
    /**
     * Проверка - долистали ли до низа страницы
     * @return {boolean}
     */
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    /**
     * Скролл до низа страницы
     */
    scrollBottom() {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
    externalLoad() {},
  },
}
